import React, { useEffect } from 'react'
import { Box, useColorModeValue } from '@chakra-ui/react'

import NavigationBar from '../Components/NavigationBar'
import Footer from '../Components/Footer'
import { Hero, Features, Ready } from '../Features/Ask'
import HowItWorks from '../Components/HowItWorks'
import { Faq } from '../Components/FAQ'

import howitworksdata from '../Config/json/Ask/how_it_works.json'
import faqdata from '../Config/json/faq.json'

import GlobalAlertDialog from '../Components/Elements/GlobalAlertDialog'

import useShouldDisplayAds from '../CustomHooks/useShouldDisplayAds'
import installGoogleAds from '../Utils/installGoogleAds'

import reactcropcss from 'react-image-crop/dist/ReactCrop.css'

export const links = () => [
  { rel: "stylesheet", href: reactcropcss },
];

export const meta = () => {
  return [
    {
      title: 'daddysteach - Meet Your Assignments Deadline',
    },
    {
      name: 'description',
      content: 'Meet your assignment deadlines with Daddysteach! Our team provides expert assistance with college homework questions to help you succeed in your studies. Get the support you need today.'
    },
  ]
}

const Index = () => {
  const shouldDisplayAds = useShouldDisplayAds()
  const backgroundColor = useColorModeValue('gray.50', 'gray.900')

  useEffect(() => {
    if (shouldDisplayAds) {
      installGoogleAds()
    }
  }, [shouldDisplayAds])

  return (
    <Box bgColor={backgroundColor}>
      <NavigationBar />
      {/* <GlobalAlerts /> */}
      <GlobalAlertDialog />
      <Hero />
      <Features />
      <HowItWorks data={howitworksdata} />
      <Faq data={faqdata[0]['ask']} />
      <Ready />
      <Footer />
    </Box>
  )
}

export default Index