const imageToBase64 = (imageObject) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()

        fileReader.onload = (e) => {
            const image = e.target.result
            resolve(image)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }

        fileReader.readAsDataURL(imageObject)
    })
}

export default imageToBase64