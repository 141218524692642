import {
    Container,
    Stack,
    Heading,
    useColorModeValue,
} from '@chakra-ui/react'

import theme from '../../../Config/theme'

import CustomHighlight from '../../../Components/Elements/CustomHighlight'
import { FeaturesItem } from '../../../Components/Features'

import Feature1 from '../../../Assets/Ask/feature_1.svg'
import Feature2 from '../../../Assets/Ask/feature_2.svg'
import Feature3 from '../../../Assets/Ask/feature_3.svg'

const Features = () => {
    const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)
    return (
        <Container
            maxW={'4xl'}
            py={10}
        >
            <Stack textAlign={'center'} pb={10}>
                <Heading fontWeight={700} fontSize={'4xl'}>
                    <CustomHighlight query={['Homework Help']}>Homework Help</CustomHighlight>
                </Heading>
            </Stack>
            <Stack direction={'column'} spacing={16}>
                <FeaturesItem
                    heading={'Homework Assistance'}
                    text={'Receive homework assistance in various subjects at any time you require assistance.'}
                    image={Feature1}
                    reverse={false}
                />
                <FeaturesItem
                    heading={'Step-By-Step Solutions'}
                    text={'We simplify homework explanations for you, making it easier for you to understand and follow.'}
                    image={Feature2}
                    reverse={true}
                />
                <FeaturesItem
                    heading={'Fast Answers'}
                    text={'We provide timely responses to your homework questions, allowing you to meet your assignment deadlines.'}
                    image={Feature3}
                    reverse={false}
                />
            </Stack>
        </Container>
    )
}

export default Features