import axios from 'axios'

import config from '../../../Config/config'

const headers = {
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
}

const handleError = (e) => {
    return {
        data: {
            error: true,
            errorType: 'serverUnavailable',
            message: 'The server is currently unavailable. Please try again later.'
        }
    }
}

const postQuestion = async (body) => {
    try {
        const response = await axios.post(config.API_BASE_URL + '/ask', body, headers)

        return response
    } catch (error) {
        return {
            data: {
                error: true,
                message: 'There was a problem sending the question. Please try again later.'
            }
        }
    }
}

const sendTextQuestion = async (body) => {
    try {
        const response = await axios.post(config.API_BASE_URL + '/ask/text-question', body, headers)

        return response
    } catch (error) {
        return handleError()
    }
}

const sendCheggQuestion = async (body) => {
    try {
        const response = await axios.post(config.API_BASE_URL + '/ask/chegg-question', body, headers)

        return response
    } catch (error) {
        return handleError()
    }
}

const fetchQuestion = async (body) => {
    const query = `?slug=${body.slug}`
    try {
        const response = await axios.get(config.API_BASE_URL + '/ask/fetch-question' + query, headers)

        return response
    } catch (error) {
        return handleError()
    }
}

export {
    sendTextQuestion,
    sendCheggQuestion,
    fetchQuestion,
    postQuestion
}