import theme from '../../../Config/theme'
import config from '../../../Config/config'

import React, { useState } from 'react'
import {
    Flex,
    Stack,
    Heading,
    Text,
} from '@chakra-ui/react'

import CustomHighlight from '../../../Components/Elements/CustomHighlight'

import QuestionTypeSwitcher from './QuestionTypeSwitcher'
import CheggQuestion from './CheggQuestion'
import TextQuestion from './TextQuestion'

const Hero = () => {
    const [selectedTab, setSelectedTab] = useState('text_question')
    //const [selectedTab, setSelectedTab] = useState('chegg_question')

    return (
        <Flex
            // bgColor='red.100'
            w={'full'}
            //h={'60vh'}
            minH={'60vh'}
            backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)), url('${config.ASK_HERO_BACKGROUND_IMAGE}')`}
            backgroundSize={'cover'}
            backgroundPosition={'center center'}
            backgroundAttachment={'fixed'}
        >
            <Stack
                // bgColor='red.100'
                direction={'column'}
                w={'full'}
                textAlign={'center'}
                align={'center'}
                justify={'center'}
            >
                <Stack direction={'column'} maxW={'3xl'} w={'full'} p={5} spacing={5}>
                    <Stack direction={'column'}>
                        <Heading
                            fontWeight={700}
                            fontSize={'4xl'}
                        >
                            <CustomHighlight query={['Meet Your Assignments Deadline']} color={'white'}>Meet Your Assignments Deadline</CustomHighlight>
                        </Heading>
                        <Text
                            color={'white'}
                            fontSize={'2xl'}
                        >
                            Get Homework Assistance. Strive For Success.
                        </Text>
                        {/* <Text
                            color={'white'}
                            fontSize={'2xl'}
                        >
                            Get Chegg Answer For Free. Strive For Success.
                        </Text> */}
                    </Stack>
                    {/* <QuestionTypeSwitcher selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> */}
                    {selectedTab === 'text_question' &&
                        <TextQuestion
                        />}

                    {selectedTab === 'chegg_question' &&
                        <CheggQuestion
                        />}
                </Stack>
            </Stack>
        </Flex>
    )
}

export default Hero