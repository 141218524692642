import theme from '../../../Config/theme'
import config from '../../../Config/config'

import React, { useState } from 'react'
import {
    Stack,
    Text,
    Button,
    useColorModeValue,
    useDisclosure,
    Textarea,
    Input,
} from '@chakra-ui/react'
import ReCAPTCHA from 'react-google-recaptcha'
import { IoSend, IoImage } from 'react-icons/io5'

import AccountRequiredModal from './AccountRequiredModal'
import ErrorWrapper from './ErrorWrapper'
import ImageUploader from './ImageUploader'
import UploadedImages from './UploadedImages'

import { sendTextQuestion } from '../Api/sendQuestion'

import useIsAuthenticated from '../../../CustomHooks/useIsAuthenticated'
import useGlobalAlertDialog from '../../../Stores/useGlobalAlertDialog'
import useHandleError from '../../../Stores/useHandleError'


const TextQuestion = () => {
    const isAuthenticated = useIsAuthenticated()
    const displayGlobalAlertDialog = useGlobalAlertDialog((state) => state.displayGlobalAlertDialog)
    const closeGlobalAlertDialog = useGlobalAlertDialog((state) => state.closeGlobalAlertDialog)
    const displayError = useHandleError((state) => state.displayError)

    const inputBgColor = useColorModeValue('gray.50', 'gray.900')
    const badgeBgColor = useColorModeValue('gray.100', 'gray.800')
    const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)
    const textColor = useColorModeValue('black', 'white')

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [question, setQuestion] = useState('')
    const [images, setImages] = useState([]) 

    const [isButtonLoading, setIsButtonLoading] = useState(false)

    const handleChange = (e) => {
        setQuestion(e.target.value)
    }

    const recaptchaRef = React.createRef();

    const handleSubmit = async () => {
        if (!isAuthenticated) {
            onOpen()
            return
        }

        const token = null
        //const token = await recaptchaRef.current.executeAsync();
        //recaptchaRef.current.reset();

        setIsButtonLoading(true)
        const response = await sendTextQuestion({ question, captchaToken: token, images })

        if (response.data.error) {
            displayError({
                active: true,
                errorType: response?.data?.errorType,
                message: response?.data?.message

            })
        } else {
            displayGlobalAlertDialog({
                title: 'Your question has been sent!',
                message: response.data.message,
                primaryButton: {
                    label: 'Ok',
                    handler: () => closeGlobalAlertDialog()
                },
                secondaryButton: {
                    label: null,
                    handler: () => null
                }
            })
            setQuestion('')
            setImages([])
        }
        setIsButtonLoading(false)
    }


    return (
        <>
            <ErrorWrapper>
                <Stack
                    direction={'column'}
                    spacing={3}
                    borderRadius={'lg'}
                    focusBorderColor={theme.default.defaultColor}
                    _focus={{}}
                    _hover={{ borderColor: theme.default.defaultColor }}
                    _active={{}}
                    bgColor={inputBgColor}
                    border={'1px'}
                    borderColor={borderColor}
                >
                    <Textarea
                        _hover={{}}
                        _focus={{}}
                        _active={{}}
                        focusBorderColor={'transparent'}
                        bgColor={'transparent'}
                        placeholder={'Type your question here'}
                        variant={'filled'}
                        size={'lg'}
                        resize={'none'}
                        color={textColor}
                        value={question}
                        onChange={handleChange}
                    ></Textarea>

                    <Stack
                        direction={'row'}
                        align={'center'}
                        justify={'end'}
                        p={2}
                        m={0}
                        bgColor={'transparent'}
                    >
                        <ReCAPTCHA
                            size={'invisible'}
                            ref={recaptchaRef}
                            sitekey={config.RECAPTCHA_SITE_KEY}
                            onExpired={() => recaptchaRef.current.reset()}
                        />

                        <ImageUploader images={images} setImages={setImages} />

                        <Text
                            bgColor={badgeBgColor}
                            borderRadius={'full'}
                            p={2}
                            color={question.length > 1000 && 'red'}
                        >
                            {question.length}/1000
                        </Text>

                        <Button
                            _focus={{}}
                            _active={{}}
                            _hover={{}}
                            size={'md'}
                            bgColor={theme.default.defaultColor}
                            w={24}
                            borderRadius={'full'}
                            rightIcon={<IoSend />}
                            color={'white'}
                            onClick={handleSubmit}
                            isLoading={isButtonLoading}
                        >
                            Send
                        </Button>
                    </Stack>
                    { /* IMAGES NOT UPLOADING ON PHONE AND 0 APPEARING ON SCREEN */}

                    <UploadedImages images={images} setImages={setImages} />
                </Stack>

                
            </ErrorWrapper>

            <AccountRequiredModal isOpen={isOpen} onClose={onClose} />
        </>
    )
}

export default TextQuestion