import theme from '../../../Config/theme'
import config from '../../../Config/config'

import { useRef, useState } from 'react'
import {
    Button,
    useColorModeValue,
    useDisclosure,
    Image,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { IoImage } from 'react-icons/io5'

import ReactCrop from 'react-image-crop'

import { PrimaryButton, TextButton } from '../../../Components/Elements/Buttons'

import imageToBase64 from '../../../Utils/imageToBase64'
import useGlobalAlertDialog from '../../../Stores/useGlobalAlertDialog'

const ImageUploader = ({ images, setImages }) => {
    const displayGlobalAlertDialog = useGlobalAlertDialog((state) => state.displayGlobalAlertDialog)
    const closeGlobalAlertDialog = useGlobalAlertDialog((state) => state.closeGlobalAlertDialog)

    const badgeBgColor = useColorModeValue('gray.100', 'gray.800')
    const { isOpen, onOpen, onClose } = useDisclosure()

    const hiddenImageInput = useRef(null)
    const [currentImage, setCurrentImage] = useState(null)

    // Image Crop
    const imageRef = useRef(null)
    const [crop, setCrop] = useState({
        unit: '%',
        width: 100,
        height: 100,
        x: 0,
        y: 0
    })
    const [croppedImage, setCroppedImage] = useState(null)

    const handleClick = () => {
        hiddenImageInput.current.click()
    }

    const handleChange = async (e) => {
        const selectedImage = e.target.files[0]
        const toBase64 = await imageToBase64(selectedImage)

        // Get base64 size in bytes
        const sizeInMB = 5
        const maxSizeInBytes = sizeInMB * 1024 * 1024

        const base64Data = toBase64.split(',')[1]
        const binaryData = window.atob(base64Data)
        const imageSizeInBytes = binaryData.length

        if (imageSizeInBytes > maxSizeInBytes) {
            displayGlobalAlertDialog({
                title: 'Exceeded Size Limit',
                message: 'The image size has surpassed the 2MB limit.',
                primaryButton: {
                    label: 'Ok',
                    handler: () => closeGlobalAlertDialog()
                },
                secondaryButton: {
                    label: null,
                    handler: () => null
                }
            })
            return
        }

        setCurrentImage(toBase64)
        onOpen()
    }

    const handleOnComplete = (image, crop) => {
        if (!crop || !image) {
            return;
        }

        const canvas = document.createElement("canvas");

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        // refer https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        var croppedImageBase64 = canvas.toDataURL("image/jpeg");
        setCroppedImage(croppedImageBase64)
    }

    const handleContinue = () => {
        setImages([...images, croppedImage || currentImage])
        onClose()
    }

    return (
        <>
            <>
                <Button
                    _focus={{}}
                    _active={{}}
                    _hover={{
                        backgroundColor: theme.default.defaultColor,
                        color: 'white'
                    }}
                    p={0}
                    borderRadius={'full'}
                    bgColor={badgeBgColor}
                    onClick={handleClick}
                    isDisabled={images.length === 3}
                >
                    <IoImage />
                </Button>

                <input
                    type={'file'}
                    accept={'image/*'}
                    ref={hiddenImageInput}
                    style={{ display: 'none' }}
                    onChange={handleChange}
                />
            </>

            <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight={'bold'}>Crop the image if required</ModalHeader>
                    <ModalCloseButton
                        _hover={{}}
                        _focus={{}}
                        _active={{}}
                    />

                    <ModalBody>
                        {/* <Image src={currentImage} /> */}
                        <ReactCrop
                            crop={crop}
                            onChange={c => setCrop(c)}
                            onComplete={(crop) => handleOnComplete(imageRef.current, crop)}
                        >
                            <Image src={currentImage} ref={imageRef} />
                        </ReactCrop>
                    </ModalBody>

                    <ModalFooter>
                        <TextButton onClick={onClose}>Cancel</TextButton>
                        <PrimaryButton w={'fit'} onClick={handleContinue}>Continue</PrimaryButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ImageUploader