import { Link } from '@remix-run/react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Text,
    useColorModeValue
} from '@chakra-ui/react'

import config from '../../../Config/config'

import PrimaryButton from '../../../Components/Elements/PrimaryButton'
import TextButton from '../../../Components/Elements/TextButton'

const AccountRequiredModal = ({ isOpen, onClose }) => {
    const bgColor = useColorModeValue('gray.100', 'gray.900')

    return (
        <AlertDialog
            motionPreset={'slideInBottom'}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <AlertDialogOverlay />

            <AlertDialogContent bgColor={bgColor}>
                <AlertDialogHeader fontWeight={'bold'}>Sign in to continue</AlertDialogHeader>
                <AlertDialogCloseButton
                    _hover={{}}
                    _focus={{}}
                    _active={{}}
                />
                <AlertDialogBody>
                    <Text fontSize={'lg'}>
                        To proceed, signing in or creating a free account is needed.
                    </Text>

                </AlertDialogBody>
                <AlertDialogFooter>
                    <Link to={config.AUTH_PAGE_URL + '/signup'}>
                        <TextButton
                            label={'Create account'}
                        />
                    </Link>
                    <Link to={config.AUTH_PAGE_URL + '/'}>
                        <PrimaryButton
                            label={'Sign in'}
                        />
                    </Link>

                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default AccountRequiredModal