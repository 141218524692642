import theme from '../../../Config/theme'
import config from '../../../Config/config'

import React, { useState } from 'react'
import {
    Stack,
    Text,
    Button,
    useColorModeValue,
    useDisclosure,
    Textarea,
    InputGroup,
    Input,
    InputRightElement
} from '@chakra-ui/react'
import ReCAPTCHA from 'react-google-recaptcha'
import { IoSend } from 'react-icons/io5'

import AccountRequiredModal from './AccountRequiredModal'
import ErrorWrapper from './ErrorWrapper'

import { sendCheggQuestion } from '../Api/sendQuestion'

import useIsAuthenticated from '../../../CustomHooks/useIsAuthenticated'
import useGlobalAlertDialog from '../../../Stores/useGlobalAlertDialog'
import useHandleError from '../../../Stores/useHandleError'

const CheggQuestion = () => {
    const isAuthenticated = useIsAuthenticated()
    const displayGlobalAlertDialog = useGlobalAlertDialog((state) => state.displayGlobalAlertDialog)
    const closeGlobalAlertDialog = useGlobalAlertDialog((state) => state.closeGlobalAlertDialog)
    const displayError = useHandleError((state) => state.displayError)

    const inputBgColor = useColorModeValue('gray.50', 'gray.900')
    const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)
    const textColor = useColorModeValue('black', 'white')

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [question, setQuestion] = useState('')
    const [isButtonLoading, setIsButtonLoading] = useState(false)

    const handleChange = (e) => {
        setQuestion(e.target.value)
    }

    const recaptchaRef = React.createRef();

    const handleSubmit = async () => {
        if (!isAuthenticated) {
            onOpen()
            return
        }

        const token = null
        //const token = await recaptchaRef.current.executeAsync();
        //recaptchaRef.current.reset();

        setIsButtonLoading(true)
        const response = await sendCheggQuestion({ question, captchaToken: token })

        if (response.data.error) {
            displayError({
                active: true,
                errorType: response?.data?.errorType,
                message: response?.data?.message

            })
        } else {
            displayGlobalAlertDialog({
                title: 'Your question has been sent!',
                message: response.data.message,
                primaryButton: {
                    label: 'Ok',
                    handler: () => closeGlobalAlertDialog()
                },
                secondaryButton: {
                    label: null,
                    handler: () => null
                }
            })
            setQuestion('')
        }
        setIsButtonLoading(false)
    }

    return (
        <>
            <ErrorWrapper>
                <InputGroup size={'lg'}>
                    <Input
                        borderRadius={'full'}
                        focusBorderColor={theme.default.defaultColor}
                        placeholder={'https://www.chegg.com/...'}
                        variant={'filled'}
                        _focus={{ bgColor: inputBgColor }}
                        _hover={{ borderColor: theme.default.defaultColor }}
                        bgColor={inputBgColor}
                        border={'1px'}
                        borderColor={borderColor}
                        value={question}
                        onChange={handleChange}
                        color={textColor}
                        size={'lg'}
                    />
                    <InputRightElement width={'auto'} align={'center'} pr={1}>
                        <ReCAPTCHA
                            size={'invisible'}
                            ref={recaptchaRef}
                            sitekey={config.RECAPTCHA_SITE_KEY}
                            onExpired={() => recaptchaRef.current.reset()}
                        />
                        <Button
                            size={'md'}
                            bgColor={theme.default.defaultColor}
                            w={24}
                            borderRadius={'full'}
                            _focus={{}}
                            _active={{}}
                            _hover={{}}
                            onClick={handleSubmit}
                            isLoading={isButtonLoading}
                            color={'white'}
                        >
                            <IoSend />
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </ErrorWrapper>

            <AccountRequiredModal isOpen={isOpen} onClose={onClose} />
        </>
    )
}

export default CheggQuestion