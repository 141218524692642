import config from '../../../Config/config'

import { useEffect } from 'react'

import useGlobalAlertDialog from '../../../Stores/useGlobalAlertDialog'
import useHandleError from '../../../Stores/useHandleError'

import { handleLogout } from '../../../Helpers/Auth'
import { createBillingPortalLink } from '../../../Api/Stripe'

const ErrorWrapper = ({ children }) => {
    const displayGlobalAlertDialog = useGlobalAlertDialog((state) => state.displayGlobalAlertDialog)
    const closeGlobalAlertDialog = useGlobalAlertDialog((state) => state.closeGlobalAlertDialog)

    const error = useHandleError((state) => state.error)

    const subscriptionErrorTypes = ["subscriptionPastDue", "subscriptionUnpaid", "subscriptionCanceled", "subscriptionIncomplete", "subscriptionPaused"]

    const handleManageSubscription = async () => {
        const response = await createBillingPortalLink()

        if (response.data.error) {
            handleLogout(false)
            window.location.replace(config.AUTH_PAGE_URL)
        } else {
            window.location.replace(response.data.url)
        }
    }
    
    useEffect(() => {
        if (error.active) {
            if (error.errorType === 'sessionInvalid') {
                displayGlobalAlertDialog({
                    title: error.errorType,
                    message: error.message,
                    primaryButton: {
                        label: 'Sign in',
                        handler: () => window.location.replace(config.AUTH_PAGE_URL + '/')
                    },
                    secondaryButton: {
                        label: 'Close',
                        handler: async () => {
                            await handleLogout(true)
                            //window.location.replace('/')
                        }
                    }
                })
                return
            }

            if (error.errorType === 'limitReached') {
                displayGlobalAlertDialog({
                    title: 'You\'ve reached your limit for this month.',
                    message: error.message,
                    primaryButton: {
                        label: 'Upgrade Now',
                        handler: () => window.location.replace(config.WEBSITE_BASE_URL + '/pricing')
                    },
                    secondaryButton: {
                        label: 'Close',
                        handler: () => closeGlobalAlertDialog()
                    }
                })
                return
            }

            if (subscriptionErrorTypes.includes(error.errorType)) {
                displayGlobalAlertDialog({
                    title: error.errorType,
                    message: error.message,
                    primaryButton: {
                        label: 'Manage Subscription',
                        handler: () => handleManageSubscription()
                    },
                    secondaryButton: {
                        label: 'Close',
                        handler: () => closeGlobalAlertDialog()
                    }
                })
                return
            }

            displayGlobalAlertDialog({
                title: 'Something\'s wrong!',
                message: error.message,
                primaryButton: {
                    label: 'Ok',
                    handler: () => closeGlobalAlertDialog()
                },
                secondaryButton: {
                    label: null,
                    handler: () => null
                }
            })
        }
    }, [error.active])

    return (
        children
    )
}

export default ErrorWrapper