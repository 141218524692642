import theme from '../../../Config/theme'
import config from '../../../Config/config'

import { useState } from 'react'

import {
    Stack,
    Text,
    Button,
    useColorModeValue,
    useDisclosure,
    Textarea,
    Box,
    Input,
    SimpleGrid,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    AspectRatio
} from '@chakra-ui/react'

import { IoEye, IoTrash } from 'react-icons/io5'


const UploadedImages = ({ images, setImages }) => {
    const inputBgColor = useColorModeValue('gray.50', 'gray.900')
    const badgeBgColor = useColorModeValue('gray.100', 'gray.800')
    const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)

    const [selectedImage, setSelectedImage] = useState(null)
    const [openedImage, setOpenedImage] = useState(null)

    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleViewImage = (index) => {
        setOpenedImage(index)
        onOpen()
    }

    const handleDeleteImage = (index) => {
        setImages(images.filter((_, i) => i != index))
    }

    return (
        <>
            {images.length > 0 &&
                <Box
                    direction={'column'}
                    spacing={3}
                    //borderRadius={'lg'}
                    focusBorderColor={theme.default.defaultColor}
                    _focus={{}}
                    //_hover={{ borderColor: theme.default.defaultColor }}
                    _hover={{}}
                    _active={{}}
                    //bgColor={inputBgColor}
                    //border={'1px'}
                    borderTop={'1px'}
                    bgColor={'transparent'}
                    borderColor={borderColor}
                    p={2}
                >
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                        {images.map((image, index) => {
                            return (
                                <Stack
                                    key={index}
                                    position={'relative'}
                                    onMouseEnter={() => setSelectedImage(index)}
                                    onMouseLeave={() => setSelectedImage(null)}
                                >
                                    <AspectRatio ratio={3 / 2}>
                                        <Image
                                            src={image}
                                            borderRadius={'md'}
                                        />
                                    </AspectRatio>

                                    {selectedImage === index &&
                                        <Stack
                                            direction={'row'}
                                            align={'center'}
                                            justify={'center'}
                                            position={'absolute'}
                                            borderRadius={'md'}
                                            //top={'-8%'}
                                            top={'-5%'}
                                            bgColor={'rgba(0, 0, 0, 0.5)'}
                                            w={'100%'}
                                            h={'100%'}
                                        >
                                            <Button
                                                _active={{}}
                                                _hover={{}}
                                                _focus={{}}
                                                background={'transparent'}
                                                p={0}
                                                color={'white'}
                                                onClick={() => handleViewImage(index)}
                                            >
                                                <IoEye />
                                            </Button>

                                            <Button
                                                _active={{}}
                                                _hover={{}}
                                                _focus={{}}
                                                background={'transparent'}
                                                p={0}
                                                color={'white'}
                                                onClick={() => handleDeleteImage(index)}
                                            >
                                                <IoTrash />
                                            </Button>
                                        </Stack>
                                    }
                                </Stack>
                            )

                        })}
                    </SimpleGrid>
                </Box>}

            <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton
                        _hover={{}}
                        _focus={{}}
                        _active={{}}
                        bgColor={theme.default.defaultColor}
                        color={'white'}
                        p={0}
                        borderRadius={'full'}
                    />
                    <ModalBody p={0} m={0}>
                        <Image src={images[openedImage]} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default UploadedImages