import {
    Container,
    Stack,
    Heading,
    Text,
    Button,
    Flex,
    useColorModeValue,
} from '@chakra-ui/react'

import CustomHighlight from '../../../Components/Elements/CustomHighlight'

import theme from '../../../Config/theme'

import { FaArrowRight } from "react-icons/fa"

const Ready = () => {
    const bgColor = useColorModeValue('gray.100', 'gray.800')
    return (
        <Flex
            w={'full'}
            py={24}
            mb={10}
            bgColor={bgColor}
        >
            <Container maxW={'4xl'}>
                <Stack direction={{ base: 'column', md: 'row' }} align={'center'} justify={'space-between'} spacing={{ base: 10 }}>
                    <Stack direction={'column'} textAlign={{ base: 'center', md: 'left' }}>
                        <Heading fontWeight={700} fontSize={'4xl'}>
                            <CustomHighlight query={['Ready?']}>Ready?</CustomHighlight>
                        </Heading>
                        <Text fontSize={'2xl'}>Submit Your First Question.</Text>
                    </Stack>
                    <Button
                        _active={{}}
                        _focus={{}}
                        _hover={{}}
                        size={'lg'}
                        borderRadius={'full'}
                        //fontWeight={700}
                        rightIcon={<FaArrowRight />}
                        color={'white'}
                        bgColor={theme.default.defaultColor}
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth"
                            });
                        }}
                    >
                        Submit First Question
                    </Button>
                </Stack>
            </Container>
        </Flex>
    )
}

export default Ready